import { fireanalytics } from '@/store/firebase'

const actions = {
  watch({ commit }, route) {
    route.beforeResolve((to, from, next) => {
      commit('ROUTE_CHANGED', to)
      next()
    })

    route.onReady(() => {
      commit('ROUTE_CHANGED', route.currentRoute)
    })
  },
  toggleSummaries({ commit, rootGetters, rootState }) {
    const reportKey = rootGetters['tools/reportKey']
    const { tools: { displaySavedFilters } } = rootState
    const display = displaySavedFilters[reportKey]
    const newSavedFiltersState = !display
    const payload = { displaySavedFilters: newSavedFiltersState, reportKey }
    const fromMap = {
      true: 'Open',
      false: 'Closed',
    }
    const toMap = {
      false: 'Open',
      true: 'Closed',
    }

    commit('tools/displaySavedFilters', payload, { root: true })

    fireanalytics.event('goal_completion', {
      category: 'Layout',
      description: 'Toggle Saved Filters',
      label: `Toggle Saved Filters from "${fromMap[newSavedFiltersState]}" to "${toMap[newSavedFiltersState]}"`,
    })

    return payload
  },
  navCollapsed({ commit }, navCollapsed) {
    commit('navCollapsed', navCollapsed)
    const fromMap = {
      true: 'Open',
      false: 'Closed',
    }
    const toMap = {
      false: 'Open',
      true: 'Closed',
    }

    fireanalytics.event('goal_completion', {
      category: 'Navigation',
      description: 'Toggle Navigation',
      label: `Toggle Navigation from "${fromMap[navCollapsed]}" to "${toMap[navCollapsed]}"`,
    })
  },
  setDarkMode({ commit, state }, inputDarkMode) {
    const inputValue = typeof inputDarkMode === 'boolean'
    const currentDarkMode = inputValue ? inputDarkMode : !state.darkMode

    commit('darkMode', currentDarkMode)

    return currentDarkMode
  },
}

export default actions
