import icons from './icons'

const { stateful: statefulIcons } = icons

const dataFreshness = {
  responses: {
    error: () => ({
      ...statefulIcons.error,
      text: 'Error fetching data refresh date',
    }),
    loading: () => ({
      ...statefulIcons.loading,
      text: 'Loading ...',
    }),
    pending: () => ({
      ...statefulIcons.loading,
      text: 'Pending ...',
    }),
    recovering: () => ({
      ...statefulIcons.recovering,
      text: 'Data recovery is in progress',
      subtext: 'Page data may be out of date',
    }),
    refreshing: () => ({
      ...statefulIcons.refreshing,
      text: 'Data is refreshing',
      subtext: 'Page load may be delayed',
    }),
    stale: (dataRefreshedTime) => ({
      ...statefulIcons.error,
      text: 'Data needs refresh',
      subtext: dataRefreshedTime,
    }),
    success: (dataRefreshedTime) => ({
      ...statefulIcons.success,
      text: 'Data refreshed',
      subtext: dataRefreshedTime,
    }),
    unknown: (dataRefreshedTime) => ({
      ...statefulIcons.error,
      text: 'Data freshness is unknown',
      subtext: dataRefreshedTime,
    }),
  },
}

export default dataFreshness
