import actionNames from './actionNames'
import STATE from './state'

const { STORAGE_KEY_NAV_COLLAPSED } = STATE

const mutations = {
  darkMode(state, darkMode) {
    state.darkMode = darkMode
  },

  [actionNames.TOGGLE_NAV](state) {
    state.navCollapsed = !state.navCollapsed

    window.localStorage.setItem(STORAGE_KEY_NAV_COLLAPSED, state.navCollapsed)
  },
  [actionNames.ROUTE_CHANGED](state, newRoute) {
    state.currentRoute = newRoute
  },
  [actionNames.ROUTE_PARAMS](state, params) {
    state.params = params
  },

  promptError(state, showHide) {
    state.promptError = showHide
  },
  promptRefresh(state, showHide) {
    state.promptRefresh = showHide
  },
  serviceWorkerRegistration(state, serviceWorkerRegistration) {
    state.serviceWorkerRegistration = serviceWorkerRegistration
  },
  navigation(state, navigation) {
    state.navigation = navigation
  },
  navCollapsed(state, navCollapsed) {
    const givenValue = [true, false].includes(navCollapsed)

    state.navCollapsed = givenValue ? navCollapsed : !state.navCollapsed
  },
  isSettingsModalVisible(state, isSettingsModalVisible) {
    state.isSettingsModalVisible = isSettingsModalVisible
  },
}

export default mutations
