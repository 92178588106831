import icons from './utils/icons'
import storage from '../../store/storage'

const { VUE_APP_VERSION } = process.env

const STORAGE_KEY_NAV_COLLAPSED = 'navCollapsed'
const drawerState = storage.getDrawerState(STORAGE_KEY_NAV_COLLAPSED)
const navCollapsed = storage.getNavCollapsedState(drawerState)

const state = () => ({
  version: VUE_APP_VERSION,
  promptError: false,
  promptRefresh: false,
  serviceWorkerRegistration: null,

  params: null,
  currentRoute: {},

  drawerState,
  navCollapsed,
  STORAGE_KEY_NAV_COLLAPSED,

  darkMode: false,
  isSettingsModalVisible: false,

  icons,
})

export default state
