import PATHS from 'src/router/paths'

const iconDashboard = require('src/assets/images/Dashboard_64x64.png')
const iconDocs = require('src/assets/images/User_Docs_64x64.png')
const iconDocsPages = require('src/assets/images/User_Docs_Pages_64x64.png')

const iconTime = require('src/assets/images/Time_64x64.png')
const iconOrg = require('src/assets/images/Organization_64x64.png')
const iconPerson = require('src/assets/images/Person_64x64.png')
const iconProject = require('src/assets/images/Project_64x64.png')
const iconExplore = require('src/assets/images/Overview_64x64.png')

const iconPacing = require('src/assets/images/Pacing_64x64.png')
const iconDiscrepancy = require('src/assets/images/Discrepancy_64x64.png')

const iconAlpha = require('src/assets/images/Alpha_64x64.png')
const iconProgrammatic = require('src/assets/images/Programmatic_64x64.png')
const iconNetworkHealth = require('src/assets/images/NetworkHealth_64x64.png')

const iconCustomReports = require('src/assets/images/CustomReport_64x64.png')

const icons = {
  navigation: {
    dashboard: iconDashboard,
    doc: iconDocsPages,
    docs: iconDocs,
    explore: iconExplore,
    organization: iconOrg,
    person: iconPerson,
    project: iconProject,
    [PATHS.CUSTOM_REPORTS.BASE]: iconCustomReports,
    time: iconTime,

    [PATHS.REPORTS.PROGRAMMATIC]: iconProgrammatic,

    [PATHS.REPORTS.DISCREPANCY]: iconDiscrepancy,
    [PATHS.REPORTS.PACING]: iconPacing,
    [PATHS.REPORTS.ADX_HEALTH]: iconAlpha,
    [PATHS.REPORTS.NETWORK_HEALTH]: iconNetworkHealth,
    [PATHS.REPORTS.YIELD_PARTNER]: iconProgrammatic,

    add: {
      doc: iconDocsPages,
      docs: iconDocs,
      explore: iconTime,
      organization: iconOrg,
      [PATHS.REPORTS.ADX_HEALTH]: iconAlpha,
      [PATHS.REPORTS.NETWORK_HEALTH]: iconNetworkHealth,
      [PATHS.REPORTS.YIELD_PARTNER]: iconAlpha,
      person: iconPerson,
      project: iconProject,
      time: iconTime,
    },
  },
  stateful: {
    defaults: {
      color: 'coolgray',
      icon: 'circle',
    },
    error: {
      color: 'toasted',
      icon: 'circle',
    },
    loading: {
      color: 'coolgray',
      icon: 'autorenew',
    },
    recovering: {
      color: 'toasted',
      icon: 'autorenew',
    },
    refreshing: {
      color: 'blue',
      icon: 'autorenew',
    },
    success: {
      color: 'offblue',
      icon: 'circle',
    },
  },
  values: {
    false: {
      color: 'toasted',
      icon: 'indeterminate_check_box',
    },
    true: {
      color: 'offblue',
      icon: 'check_box',
    },
    'n/a': {
      color: 'midgray',
      icon: 'indeterminate_check_box',
    },
    null: {
      color: 'midgray',
      icon: 'check_box_outline_blank',
    },
    undefined: {
      color: 'midgray',
      icon: 'check_box_outline_blank',
    },
  },
}

export default icons
