import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import states from './state'

const store = {
  namespaced: true,
  state: states,
  mutations,
  getters,
  actions,
}

export default store
