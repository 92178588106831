const GETTERS = {
  showSearchBar(state, getters, rootState) {
    const { auth: { user } } = rootState

    if (!user) return false

    return true
  },

  isProfilePage(state, getters, rootState, rootGetters) {
    const { selectedSearch } = rootState.tools
    const isInternalRoute = rootGetters['tools/isInternalRoute']
    return isInternalRoute && selectedSearch.dataType !== 'custom'
  },
  isNetworkReportOption(state, getters, rootState, rootGetters) {
    const { selectedSearch } = rootState.tools
    const isInternalRoute = rootGetters['tools/isInternalRoute']
    return !isInternalRoute && selectedSearch?.dataType !== 'custom'
  },
  isNetworkDashboardRoute(state, getters, rootState, rootGetters) {
    const { selectedSearch } = rootState.tools
    const isInternalRoute = rootGetters['tools/isInternalRoute']

    const isCustomOption = () => selectedSearch.dataType === 'custom'

    return selectedSearch && !isInternalRoute && isCustomOption()
  },
  showDataTools(state, getters, rootState) {
    const { selectedSearch } = rootState.tools
    const { isNetworkDashboardRoute, isProfilePage } = getters
    return selectedSearch && !isNetworkDashboardRoute && !isProfilePage
  },

  showLayoutBar(state, getters, rootState, rootGetters) {
    const { showDataTools } = getters
    const isReportRoute = rootGetters['tools/isReportRoute']

    return showDataTools && isReportRoute
  },
  showLegendBar(state, getters, rootState, rootGetters) {
    const { showDataTools } = getters
    const reportKey = rootGetters['tools/reportKey']
    const isLegendRoute = ['discrepancy', 'pacing'].indexOf(reportKey) > -1

    return showDataTools && isLegendRoute
  },
  showExportBar(state, getters) {
    const { showDataTools } = getters

    return showDataTools
  },
  showFilterBar(state, getters) {
    const { showDataTools } = getters

    return showDataTools
  },
  showDataFreshness(state, getters) {
    const { showDataTools } = getters

    return showDataTools
  },
}

export default GETTERS
